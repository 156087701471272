import { setFragment, setLocation } from 'bv-helpers/location';

export const moveToEnableTotpScreen = () => { setFragment('/preferences/twofactorauth/totp'); };
export const moveToEnableSmsScreen = () => { setFragment('/preferences/twofactorauth/sms'); };
export const moveToEnableTwoFA = () => { setFragment('/preferences/twofactorauth/code'); };
export const moveToTotpScan = () => { setFragment('/preferences/twofactorauth/totp/scan'); };
export const redirectToHome = () => { setLocation('/'); };
export const redirectToSettings = () => { setLocation('/preferences/edit'); };
export const redirectToLogin = () => { setLocation('/login'); };
export const moveToPreferences = () => { setFragment('/preferences/edit'); };
export const moveToEnableScreen = () => { setFragment('/preferences/twofactorauth/enable'); };
