import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { v as bvVar } from 'bv';
import { showDangerMessage } from 'modal-helper';
import { withFetching } from 'bv-hocs';
import {
  IntroText,
  DotContainer,
  WrongFeedback,
  CodeInput,
  SendAgain,
  SendCodeSpinner,
} from 'SharedComponents/send_code_from_sms';
import SuccessFeedback from './success/success_feedback';
import ChooseLink from './choose_link';
import Chooser from './chooser';
import SendAgainControl from './send_again_control';
import { getTwofaMethod, validateCode, sendCode } from '../helpers/api';
import { AppStore, execCallback } from '../helpers/app_store';
import { getOnSuccess, getValidateEndpoint } from '../helpers/common';
import { getPosition } from '../../../login/services/location_check_timer';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: '',
      codeValue: '',
      inputValue: '',
      disabled: false,
      retrying: false,
      triesLeft: false,
      showWrongFeedback: false,
      login: !!props.login,
      trustValue: false,
      loading: false,
      maxCodeLength: 6,
      contactUsUrl: '',
      wasCodeResent: false,
      disableResentButton: false,
      cooldown: 0,
      brand: '',
      chooser: false,
      currentMethod: null,
      availableMethods: [],
      needLocationCheck: !!props.needLocationCheck,
      position: null,
      positionPromise: null,
    };

    this.codeInputRef = createRef();
  }

  componentDidMount() {
    const { twofaMethodsData } = this.props;
    this.handleMethodChange(twofaMethodsData);
    this.focusCodeInput();
    this.requestLocation();
  }

  componentDidUpdate() {
    this.focusCodeInput();
  }

  onKeyUp = (event) => {
    if (event.key === 'Backspace' || event.keyCode === 8) {
      const { codeValue, maxCodeLength, status } = this.state;

      this.setState({
        codeValue: codeValue.length === maxCodeLength ? '' : codeValue.slice(0, -1),
        status: codeValue.length === maxCodeLength ? '' : status,
        inputValue: '',
      });
    }
  };

  onChange = async (event) => {
    const {
      disabled, codeValue, maxCodeLength, trustValue, needLocationCheck, positionPromise,
    } = this.state;
    const { login } = this.props;
    if (disabled) {
      return;
    }

    const tmpValue = event.target.value.replace(/\D/g, '');
    const digit = tmpValue.substr(0, 1);
    const code = this.handleCodeChange(`${codeValue}${digit}`);
    this.setState({
      codeValue: code,
      inputValue: '',
    });

    if (code.length === maxCodeLength) {
      this.setState({ disabled: true });
      this.setLoading(true);

      if (needLocationCheck) {
        const timeout = new Promise((resolve) => { setTimeout(resolve, 5000); });
        try {
          await Promise.race(positionPromise, timeout);
        } catch (e) {
          // ignore rejection, continue without coordinates
        }
      }
      const { position } = this.state;
      validateCode(getValidateEndpoint(login), code, trustValue, position).then((response) => {
        this.handleFeedbackStatus(response);
      });
    }
  };

  setLoading = (loading) => {
    this.setState({
      loading,
    });
  };

  requestLocation = () => {
    const { needLocationCheck, position } = this.state;
    if (needLocationCheck && !position) {
      const positionPromise = getPosition(false).then((pos) => {
        this.setState({ position: pos });
      }).catch(() => {
        // ignore rejection by timeout or by user
        this.setState({ position: null });
      });
      this.setState({ positionPromise });
    }
  };

  handleCodeChange = (value) => {
    const { retrying, maxCodeLength } = this.state;

    if (retrying) {
      this.setState({
        status: '',
        retrying: false,
      });
      // if length is greater than max, code should be cleaned and kept the last char
      return value.length > maxCodeLength ? value.substr(-1, 1) : '';
    }

    return value.substr(0, maxCodeLength);
  };

  handleFeedbackStatus = (response) => {
    const { login, contactUsUrl } = this.state;

    this.setLoading(false);
    this.requestLocation();
    this.setState(AppStore(response, this.props));
    execCallback(response, this.props, login, contactUsUrl);
  };

  enableResendButton = (cooldownInterval) => {
    clearInterval(cooldownInterval);
    this.setState({ wasCodeResent: false, disableResentButton: false });
  };

  sendCodeHandler = () => {
    const { login } = this.state;
    const { sendAgainEndpoint } = this.props;

    this.setLoading(true);
    sendCode(sendAgainEndpoint, login)
      .then((data) => {
        this.setLoading(false);
        this.requestLocation();

        this.setState({
          wasCodeResent: data.success,
          disableResentButton: true,
          cooldown: data.cooldown,
        });

        const cooldownInterval = setInterval(() => {
          this.setState((prevState) => ({ cooldown: prevState.cooldown - 1 }));
        }, 1000);

        setTimeout(() => { this.enableResendButton(cooldownInterval); }, data.cooldown * 1000);
        if (data.success === false) {
          showDangerMessage({
            message: t('javascript.twofa.error.general'),
            actions: [
              {
                label: t('javascript.twofa.settings.ok_button'), id: 'ok-btn', danger: true, close: true,
              },
            ],
            dataLayer: {
              error_message: t('javascript.twofa.error.general'),
              error_key: 'javascript.twofa.error.general',
              error_code: 'E0x005',
            },
          });
        }
      });
  };

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      trustValue: !prevState.trustValue,
    }));
  };

  focusCodeInput = () => {
    if (this.codeInputRef.current) this.codeInputRef.current.focus();
  };

  toggleChooser = () => {
    const { chooser } = this.state;

    this.setState({
      chooser: !chooser,
    });
  };

  handleMethodChange = (data) => {
    this.setState({
      status: '',
      codeValue: '',
      inputValue: '',
      disabled: false,
      retrying: false,
      triesLeft: false,
      showWrongFeedback: false,
      currentMethod: data.method,
      availableMethods: data.availableMethods,
      contactUsUrl: data.contactUsUrl || bvVar('contactUsPageUrl'),
      chooser: false,
    });
  };

  render() {
    const { state } = this;
    const { login } = this.props;

    if (state.status === 'success') {
      return (
        <SuccessFeedback
          login={state.login}
          method={state.currentMethod}
          redirect={getOnSuccess(login)}
          codeValue={state.codeValue}
          brand={state.brand}
        />
      );
    }

    if (state.chooser) {
      return (
        <Chooser
          methods={state.availableMethods}
          help={state.contactUsUrl}
          callback={this.handleMethodChange}
        />
      );
    }

    return (
      <div className="send-verify-code" onClick={this.focusCodeInput}>
        { state.loading && <SendCodeSpinner /> }
        <IntroText
          description={state.currentMethod && t(`javascript.twofa.code.${state.currentMethod}.description`)}
          enterCode={state.currentMethod && t(`javascript.twofa.code.${state.currentMethod}.enter_code`)}
          showTrustCheckbox={state.login}
          checked={state.trustValue}
          onChange={this.handleCheckboxChange}
          icon={<div className="send-verify-code__header-icon" />}
        />
        <DotContainer code={state.codeValue} status={state.status} />
        { state.showWrongFeedback
          && <WrongFeedback status={state.status} triesLeft={state.triesLeft} /> }
        <CodeInput
          ref={this.codeInputRef}
          maxCodeLength={state.maxCodeLength}
          value={state.inputValue}
          handleCodeInputChange={this.onChange}
          handleCodeInputKeyPress={this.onKeyUp}
        />
        <SendAgain
          method={state.currentMethod}
          wasCodeResent={state.wasCodeResent}
          cooldown={state.cooldown}
          control={(
            <SendAgainControl
              disabled={state.disableResentButton}
              handleClick={this.sendCodeHandler}
            />
          )}
        />
        <ChooseLink
          login={state.login}
          toggleChooser={this.toggleChooser}
        />
      </div>
    );
  }
}

App.propTypes = {
  login: PropTypes.bool,
  needLocationCheck: PropTypes.bool,
  sendAgainEndpoint: PropTypes.string,
  twofaMethodsData: PropTypes.shape({
    currentMethod: PropTypes.string,
    availableMethods: PropTypes.arrayOf(PropTypes.string),
    contactUsUrl: PropTypes.string,
  }),
};

App.defaultProps = {
  login: false,
  needLocationCheck: false,
  sendAgainEndpoint: '/bv_api/twofactorauth/sendcode',
  twofaMethodsData: PropTypes.shape({
    currentMethod: '',
    availableMethods: [],
    contactUsUrl: '',
  }),
};

export default withFetching(getTwofaMethod, 'twofaMethodsData')(App);
