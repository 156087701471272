import { getJSON, postJSON } from 'bv-fetch';

export const getTwofaMethod = () => (
  getJSON('/bv_api/two_factor_auth/status/methods').catch(() => {})
);

export const setTwofaMethod = (method) => (
  postJSON('/bv_api/two_factor_auth/status/methods', { method })
);

export const validateCode = (endpoint, code, trustValue, position = null) => (
  postJSON(endpoint, {
    code,
    trustValue,
    ...(position ? { position } : {}),
  })
);

export const sendCode = (endpoint, login) => (
  postJSON(endpoint, { login })
);
