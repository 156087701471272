import PropTypes from 'prop-types';
import { t } from 'bv-i18n';
import { setTwofaMethod } from '../helpers/api';

const { removeModal } = window.reduxState.store.actionDispatchers.modal;

const Option = ({ value, callback, last = false }) => {
  const onClick = () => {
    callback(value);
  };

  return (
    <>
      <div className="twofa-code-chooser__method">
        <div className={`bvs-icon twofa-icon twofa-icon--${value}`} />
        <span
          className="twofa-code-chooser__method-link bvs-text-info"
          onClick={onClick}
        >
          {t(`javascript.twofa.settings.try_another_way.${value}`)}
        </span>
      </div>
      {!last && <hr className="twofa-header__horizontal-bar" />}
    </>
  );
};

Option.propTypes = {
  value: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  last: PropTypes.bool.isRequired,
};

const Chooser = ({ methods = [], help, callback }) => {
  const totp = methods.includes('totp');
  const sms = methods.includes('sms');

  const redirectToContactUs = () => {
    removeModal();
    window.location.href = help;
  };

  const chooseMethod = (value) => {
    setTwofaMethod(value).then(
      (data) => callback(data),
    );
  };

  return (
    <div className="bvs-card twofa-code-chooser">
      <div className="twofa-code-chooser__title">{t('javascript.twofa.settings.try_another_way.title')}</div>
      {totp && <Option value="totp" callback={chooseMethod} />}
      {sms && <Option value="sms" callback={chooseMethod} />}
      <Option value="help" callback={redirectToContactUs} last />
    </div>
  );
};

Chooser.propTypes = {
  methods: PropTypes.arrayOf(PropTypes.string).isRequired,
  help: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
};

export default Chooser;
