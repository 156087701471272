import PropTypes from 'prop-types';
import { ModalWindow } from 'bv-components';
import { t } from 'bv-i18n';
import App from './components/app';

const CodeFeedbackModal = (props) => {
  const { login } = props;

  return (
    <ModalWindow
      title={t(`javascript.twofa.navigation_title.${login ? 'twofa' : 'mobile_sms_verification'}`)}
      className="two-factor-modal"
    >
      <App {...props} />
    </ModalWindow>
  );
};

CodeFeedbackModal.propTypes = {
  login: PropTypes.bool.isRequired,
};

export default CodeFeedbackModal;
