import { setLocation } from 'bv-helpers/location';
import { mainContextPath } from 'bv-services/route';

export const continueLogin = (data) => {
  // TODO: Merge with login view after login logic
  if (data.redirect) {
    setLocation(data.redirect);
  } else {
    setLocation(`${mainContextPath() || '/'}`);
  }
};

export const redirectToDeviceManager = () => { setLocation('/preferences/twofactorauth/devicemanager'); };

export const getValidateEndpoint = (login) => (
  login ? '/login_continue' : '/bv_api/twofactorauth/validatecode'
);

export const getOnSuccess = (login) => (
  login ? continueLogin : redirectToDeviceManager
);
